
import { defineComponent } from "vue";
import { useHead } from "@vueuse/head";
import { data } from "@/shared/policy/terms/terms_v1.0.0";
import TermsMd from "@/shared/policy/terms/terms_v2.0.0.md";
import Markdown from "vue3-markdown-it";
export default defineComponent({
  name: "TermsMobile",
  components: {
    Markdown
  },
  data() {
    return {
      source: TermsMd
    };
  },
  setup() {
    useHead({ title: data.sitemapTitle });
    return {
      data
    };
  }
});
